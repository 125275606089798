import React from 'react'
import './Cookies.css'
//import CookieCard from './CookieCard'

function Cookies() {
    return (
        
        <div>
        <section className="cookie-section">
        <div className="cookie-main">
            <h2>Shop Cookies</h2>
            <hr/>

            <div className="cookie-info">
            <div>
                <h3>Regular Cookies</h3>
                <hr/>
                <h4>$20 for a half dozen</h4>
                <h4>$25 for a dozen</h4>
            </div>
            <div>
                <h3>Vegan Cookies</h3>
                <hr/>
                <h4>$25 for a half dozen</h4>
                <h4>$30 for a dozen</h4>
            </div>
        </div>

            <div className="cookie-content">

            <div className="cookie-card">
            <div className="cookie-card-image">
            <img src="/images/img-gal-5.png" alt="cake-img" />
            </div>
            <div className="cookie-card-content">
                <h3> OATMEAL APPLE PIE </h3>
                
                <button> Add to cart</button>
            </div>
        </div>

        <div className="cookie-card">
            <div className="cookie-card-image">
            <img src="/images/pump_oat.png" alt="cake-img" />
            </div>
            <div className="cookie-card-content">
                <h3> PUMPKIN OATMEAL </h3>
                
                <button> Add to cart</button>
            </div>
        </div>

        <div className="cookie-card">
        <div className="cookie-card-image">
        <img src="/images/gal-img-2.png" alt="cake-img" />
            
        </div>
        <div className="cookie-card-content">
            <h3> OATMEAL RAISIN </h3>
            
            <button> Add to cart</button>
        </div>
    </div>

    <div className="cookie-card">
    <div className="cookie-card-image">
    <img src="/images/peanut_stack.png" alt="cake-img" />
        
    </div>
    <div className="cookie-card-content">
        <h3> PEANUT BUTTER </h3>
        
        <button> Add to cart</button>
    </div>
</div>


<div className="cookie-card">
<div className="cookie-card-image">
<img src="/images/oatmealcoco_stack.png" alt="cake-img" />
    
</div>
<div className="cookie-card-content">
    <h3> Toasted Almond coconut oatmeal </h3>
    
    <button> Add to cart</button>
</div>
</div>

<div className="cookie-card">
            <div className="cookie-card-image">
            <img src="/images/vegan_stack.png" alt="cake-img" />
            </div>
            <div className="cookie-card-content">
                <h3> VEGAN COOKIE </h3>
                
                <button> Add to cart</button>
            </div>
        </div>

        <div className="cookie-card">
            <div className="cookie-card-image">
            <img src="/images/vegancoco_stack.png" alt="cake-img" />
            </div>
            <div className="cookie-card-content">
                <h3> Toasted coconut almond vegan </h3>
                
                <button> Add to cart</button>
            </div>
        </div>
             
                 

            </div>
        </div>
    </section>

        </div>
    )
}

export default Cookies
