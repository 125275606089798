import React from 'react'
import AboutHero from '../components/about/AboutHero'

function AboutPage() {
    return (
        <div>
            <AboutHero />
        </div>
    )
}

export default AboutPage
