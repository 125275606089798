import React from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Navbar from './components/navbar/Navbar'
import Footer from './components/footer/Footer'
import ShopPage from './pages/ShopPage'
import AboutPage from './pages/AboutPage'
import ContactPage from './pages/ContactPage'
import MerchPage from './pages/MerchPage';
import HomePage from './pages/HomePage'


function App() {
  return (
    <div className="App">
      
        <BrowserRouter>
        <Navbar />
          <Switch>
            <Route path="/" exact component={ HomePage }></Route>
            <Route path="/about" component={ AboutPage }></Route>
            <Route path="/contact"  component={ ContactPage }></Route>
            <Route path="/shop"  component={ ShopPage }></Route>
            <Route path="/merch" component={ MerchPage }></Route>
          </Switch>
          <Footer />
        </BrowserRouter>
      
      
    </div>
  );
}

export default App;
