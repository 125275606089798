import React from 'react'
import './AboutHero.css'

function AboutHero() {
    return (
        <div>
        <section className="about-section">
        <div className="about-content">
            <div className="about-content-1">
                <h2>The Owner<i className="fas fa-crown"></i></h2>
                <hr/>
                <p>Dominise Duliepre is the Founder and Head Pastry Chef at D’Essence Bakery. 
                Dominise is originally from Brooklyn, NY, and moved to the Charlotte area in 2009. 
                Dominise has had a passion for the art of food since the tender young age of 8. 
                She spent many evenings in the kitchen with both her grandmothers', cooking, and baking. 
                When it was time for college, she attended the prestigious Johnson & Wales University. 
                She graduated with her Associate's Degree in Baking and Pastry Arts, and her Bachelor’s Degree in Food & Beverage Entrepreneurship. 
                Dominise has a genuine love of watching people's faces light up tasting her delicious creations. When the time was finally right, D'Essence Bakery was born! An online bakery providing quality, homemade desserts serving the Charlotte & Greensboro areas. 
                We can't wait to serve you for your SWEET needs! </p>
            </div>
        </div>
        <div className="about-image">
            <img src="/images/about.png" alt="" />
        </div>
    </section>
        </div>
    )
}

export default AboutHero
