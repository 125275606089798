import React from 'react'
import { Link } from 'react-router-dom'
import './SlideShow.css'
import defaultSlides from './defaultSlides.js';

const Slider = ({ slides }) => {
  const [curr, setCurr] = React.useState(0);
  const { length } = slides;
  
  const goToNext = () => {
    setCurr(curr === length - 1 ? 0 : curr + 1);
  }
  
  React.useEffect(() => {
    setTimeout(goToNext, 4000)
  })
  
  if (!Array.isArray(slides) || length <= 0) {
    return null;
  }
  
  return (
    <section className="slider">
      {slides.map((s, i) => (
        <div className={i === curr ? "slide active" : "slide"} key={s.title} aria-hidden={i !== curr}>
          <div className="slide-content">
            <h1><b>100% fresh</b><br/>Homemade<br/>Bakery</h1>
            <Link to="/shop"><button className="primary-btn">SHOP NOW</button></Link>
          </div>
          {i === curr && (
            <img className="image" src={s.image} alt={s.title} />
          )}
        </div>
      ))}
    </section>
  );  
}

const SlideShow = () => {
  return (
    <main>
      <Slider slides={defaultSlides} />
    </main>
  );
}


export default SlideShow


