import React from 'react'
import Contact from '../components/contact/Contact'
function ContactPage() {
    return (
        <div>
            <Contact />
        </div>
    )
}

export default ContactPage
