import React from 'react'
import Cakes from '../components/cakes/Cakes'
import Cookies from '../components/cookies/Cookies'

function ShopPage() {
    return (
        <div>
            <Cakes />
            <Cookies />
        </div>
    )
}

export default ShopPage
