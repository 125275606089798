import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

function Footer() {
    return (
        <div>
            <footer>
            <div className="footer-container">
            <div className="footer-nav footer-nav-shop">
            <h4>SHOP</h4>
            <ul>
                <li>
                    <Link to="/about">About Me</Link>
                </li>
                {/** <li><a href="#">Shipping / Pick Up </a></li>*/}
                {/** <li><a href="#"> Store Policy</a></li>*/}
                <li>
                    <Link to="/contact">Contact Me</Link>
                </li>
            </ul>
            </div>
            <div className="footer-nav footer-nav-hours">
                <h4>OPENING HOURS</h4>
                <ul>
                    <li>Mon - Fri: 7am - 10pm  </li>
                    <li>Saturday: 8am - 10pm</li>
                    <li>Sunday: 8am - 11pm</li>
                </ul>
            </div>
            <div className="footer-nav footer-nav-address">
                <h4>ADDRESS</h4>
                <ul>
                    <li>Charlotte, NC</li>
                </ul>
            </div>
            <div className="footer-nav footer-newsletter">
                <h4>GET IT FRESH</h4>
                <input placeholder="Enter Your Email Here"/>
                <button>SUBSCRIBE NOW</button>

                <ul>
                    <li>
                        <a href="https://www.instagram.com/d_essencebakery/?igshid=3dyagbumooa7"><i className="fab fa-instagram">Instagram</i></a>
                    </li>
                    <li><a href="https://www.gmail.com"><i className="far fa-envelope">dessencebakery@gmail.com</i></a></li>

                   {/**<li>
                        <a href="#"><i className="fab fa-twitter"></i></a>
                    </li>
                    <li>
                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                </li> */} 
                </ul>
            </div>
            </div>
            </footer>
        </div>
    )
}

export default Footer
