import React, { Component } from 'react'
import './ContactForm.css'

export default class ContactForm extends Component {
    

   

    render() {

        return (
            <div>
            <form
            method="POST"
            >
                    <input type="text" name="name" className="field" placeholder="Enter Name"/>
                    <input type="email" className="field" placeholder="Enter Email Address"/>
                    <input type="text" className="field" placeholder="Enter Phone Number"/>
                    <textarea name="message" className="field" placeholder="Enter Message"></textarea>
                  
                    <button type="submit">Send Message</button>
                    
                </form>
                
            </div>
        )
    }
    
}
