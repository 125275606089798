import React from 'react'
import './MerchBanner.css'

function MerchBanner() {
    return (
        <div>
        <section className="merch-section">
        <div>
            <h1>Merchandise Coming Soon!!</h1>
            <i className="far fa-smile-beam fa-7x"></i>
        </div>
    </section>
        </div>
    )
}

export default MerchBanner
