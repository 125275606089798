export default [
    {
        title: 'First slide!',
        subtitle: 'is it working?',
        image: '../images/cake-pic.jpeg',
    },
    {
        title: "Second Slide",
        subtitle: "ohhh yeah",
        image: '../images/cake-pic-2.jpeg',
    },
    {
        title: "Third Slide",
        subtitle: "last one! ✨",
        image: '../images/cake-pic-3.jpeg',
    },
]