import React from 'react'
import { Link } from 'react-router-dom'

import './Navbar.css'

function Navbar() {
    return (
        <div>
       

        <header>
            <div>
                <ul>
                <li><a href="https://www.gmail.com"><i className="far fa-envelope">dessencebakery@gmail.com</i></a></li>
            <li>
                <a href="https://www.instagram.com/d_essencebakery/?igshid=3dyagbumooa7"><i className="fab fa-instagram">Instagram</i></a>
            </li>
                </ul>
            </div>
        </header>
    
    
        <div className="navbar">
            <div>
                <ul className="navbar-content">
                    <li>
                        <ul className="navbar-menu navbar-menu-1">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/shop">Shop</Link>
                            </li>
                            <li>
                                <Link to="/merch">Merch</Link>
                            </li>
                        </ul>
                    </li>
    
                    <li>
                        <div className="logo">
                            <img src="/images/logo.png" alt="logo.png" />
                        </div>
                    </li>
    
                    <li>
                        <ul className="navbar-menu navbar-menu-2">
                            <li>
                            <Link to="/about">About</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                        <li>
                            <Link to="/cart">Cart<i className="fas fa-shopping-cart"></i></Link>
                        </li>
                          
                        </ul>
                    </li>
                </ul>
            </div>
        </div>

            
        </div>
    )
}

export default Navbar
