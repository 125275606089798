import React from 'react'
import MerchBanner from '../components/merch/MerchBanner'

function MerchPage() {
    return (
        <div>
            <MerchBanner/>
        </div>
    )
}

export default MerchPage
