import React from 'react'
import './HomePage.css'
import { Link } from 'react-router-dom'
import SlideShow from '../components/slideshow/SlideShow'

function HomePage() {



    return (
        <div>

 
        <SlideShow/>
    
        

    <section className="hero-section">
        <div className="hero hero-1">
            <div className="hero-content-1">
                <h2>Fall Is Here<i className="fab fa-canadian-maple-leaf"></i></h2>
                <hr/>
                <p>Try our limited edition Pumkin Oatmeal Cookies.</p>
                <p>Welcome in fall with our Pumkin Oatmeal Cookies, but hurry they wont last long. Get it while in stock</p>
                <Link to="/shop">
                <button>Shop now</button>
                </Link>
                
            </div>
            <div className="hero-image-1">
                <img src="../images/pump_oat.png" alt="" />
            </div>
        </div>

        <div className="hero hero-2">
            <div className="hero-image-2">
                <img src="../images/hero-cake.png" alt=""/>
            </div>

            <div className="hero-content-2">
                <h2>Today's Fetured<i className="fas fa-bookmark"></i> </h2>
                <hr/>
                <p>Birthday cakes are one of our specialties. Check out our single tier birthday cakes to celebrate your special day.</p>
                <Link to="/shop">
                <button>Shop now</button>
                </Link>
            </div>
        </div>
        
    </section>

    <section className="newsletter-section">
        <div className="newsletter-content">
            <h2>Keep up with the latest treats and promotions<br/>Sign up for our newsletter</h2>
            <input placeholder="Enter Your Email Here"/>
            <div className="newsletter-btn"><button>Subscribe Now</button></div>
        </div>
    </section>

    <section className="gallery-section">
        <div className="gallery-title">
            <h2>Gallery</h2>
            <hr/>
        </div>
        <div className="gallery-content">
            <div className="gallery-image"><img src="../images/pump_oat.png" alt=""/></div>
            <div className="gallery-image"><img src="../images/img-gal-6.png" alt=""/></div>
            <div className="gallery-image"><img src="../images/img-gal-5.png" alt=""/></div>
            <div className="gallery-image"><img src="../images/hero-cake.png" alt=""/></div>
            <div className="gallery-image"><img src="../images/cake-gal.png" alt=""/></div>
            <div className="gallery-image"><img src="../images/gal-img-2.png" alt=""/></div>
            <div className="gallery-image"><img src="../images/gal-img-3.png" alt=""/></div>
            <div className="gallery-image"><img src="../images/img-gal-5.png" alt=""/></div>
            <div className="gallery-image"><img src="../images/gal-img-4.png" alt=""/></div>
        </div>
        <a href="https://www.instagram.com/d_essencebakery/?igshid=3dyagbumooa7"><button>See our Instagram</button></a>
    </section>

    <section className="delivery-section">
        <div className="delivery-content-1">
        <h2>Delivery</h2>
        <p>We deliver in the Charlotte and Grennsboro area</p>
        <p>Delivery Fee $6</p>
            <i class="fas fa-car fa-2x"></i>
        </div>
        <div className="delivery-content-2">
        <h2>Shipping</h2>
        <p>Need your order shipped? No worries </p>
        <p>Shipping Fee $10</p>
            <i class="fas fa-arrow-alt-circle-down fa-2x"></i>
        </div>
        
    </section>
</div>
    )
}

export default HomePage
