import React from 'react'
import './Contact.css'
import ContactForm from './ContactForm'

function Contact() {
    return (
        <div>
        <section className="contact-section">
        <div className="contact-main">
            <div className="contact-form">
                <h2> Contact Me</h2>
                <hr />
                <ContactForm />
            </div>

            <div className="contact-content">
                <div>
                    <h2> Phone</h2>
                    <hr />
                    <h4>+1 (917) 620-7664</h4>
                    <h4>+1 (704) 918-5695</h4>
                </div>
                <div>
                    <h2> Email</h2>
                    <hr />
                    <h4>dessencebakery@gmail.com</h4>
                </div>
                <div>
                    <h2> Social Media</h2>
                    <hr />
                        <ul>
                        <li>
                        <a href="https://www.instagram.com/d_essencebakery/?igshid=3dyagbumooa7"><i className="fab fa-instagram">Instagram</i></a>
                    </li>
                        </ul>
                </div>
            </div>
        </div>
    </section>
        </div>
    )
}

export default Contact
