import React from 'react'
import { Link } from 'react-router-dom'
import './Cakes.css'
function Cakes() {
    return (
        <div>
        <section className="cake-section">
        <div className="cake-main">
            <h2>Shop Cakes</h2>
            <hr/>

            <div className="cake-content">
                
                <div className="cake-card">
                    <div className="cake-card-image">
                        <img src="/images/cake-gal.png" alt="cake-img" />
                    </div>
                </div>
                
                <div className="cake-card">
                    <div className="cake-card-image">
                        <img src="/images/hero-cake.png" alt="cake-img" />
                    </div>
                </div>
              
                <div className="cake-card">
                    <div className="cake-card-image">
                        <img src="/images/img-gal-6.png" alt="cake-img" />
                    </div>
                </div>

            </div>

            <div className="cake-card-content">
                <h3>Please conctact us for cake information 
                to find the best cake for the best price</h3>
                <Link to="/contact"><button> Contact Me </button></Link>
            </div>
        </div>
    </section>
        </div>
    )
}

export default Cakes
